import _ from 'lodash';
import { SetterOrUpdater } from 'recoil';
import IApiCaller from '../types/api-caller';
import { ICompanySummary, IListStatistics } from '../types/company';
import { getDowngradeBucket } from '../utils/downgrade-utilities';
import { toast } from 'react-toastify';

const filterCompaniesByUserList = (userList: string, companies: ICompanySummary[]): ICompanySummary[] => {
  return companies.filter((company) => company.user_list.includes(userList));
};

const getNumberOfCompanies = (filteredCompanies: ICompanySummary[]): number => {
  return _.size(filteredCompanies);
};

// exclude score that are exactly 0 from the average
const getAverageScore = (filteredCompanies: ICompanySummary[]): number => {
  const withoutNoArticles = _.filter(filteredCompanies, (company) => company.current_score !== 0);
  if (_.size(withoutNoArticles) === 0) {
    return 0;
  }

  return Math.round(_.meanBy(withoutNoArticles, 'current_score'));
};

const getDailyPercentChange = (filteredCompanies: ICompanySummary[]): number => {
  return Math.round(_.meanBy(filteredCompanies, 'previous_day_percent_change') * 10) / 10;
};

const getWeeklyPercentChange = (filteredCompanies: ICompanySummary[]): number => {
  return Math.round(_.meanBy(filteredCompanies, 'previous_week_percent_change') * 10) / 10;
};

export const createUserListStats = (userLists: string[], companies: ICompanySummary[]): IListStatistics[] => {
  return userLists.map((userList) => {
    const filteredCompanies = filterCompaniesByUserList(userList, companies);
    const current_score = getAverageScore(filteredCompanies);
    const bucket = getDowngradeBucket(current_score / 100.0);
    return {
      listName: userList,
      numberOfCompanies: getNumberOfCompanies(filteredCompanies),
      current_score: current_score,
      current_score_color_name: bucket.color,
      current_score_sort_order: bucket.sortOrder,
      current_score_text: bucket.text,
      previous_day_percent_change: getDailyPercentChange(filteredCompanies),
      previous_week_percent_change: getWeeklyPercentChange(filteredCompanies),
    };
  });
};

export const onUserListAssignmentChange = (
  companies: ICompanySummary[],
  setCompanies: SetterOrUpdater<ICompanySummary[]>,
  apiCaller: IApiCaller,
  companyCode: string,
  companyName: string,
  userList: string[]
): void => {
  let localCompanies = [...companies];
  const index = localCompanies.findIndex((company) => company.company_code === companyCode);
  if (index !== -1) {
    const localCompany = { ...localCompanies[index] };
    localCompany.user_list = userList;
    localCompanies[index] = localCompany;
    setCompanies(localCompanies);
    apiCaller.saveCompanyUserList(companyCode, userList).catch((err: any) => {
      const msg = `Did not save ${companyName}`;
      console.error(err, msg);
      toast.error(msg);
    });
  }
};

// just a convenience so user does not have to individually delete each assignment
//TODO: add visual element while working
export const onUserListDeletion = (
  companies: ICompanySummary[],
  setCompanies: SetterOrUpdater<ICompanySummary[]>,
  apiCaller: IApiCaller,
  userList: string
): void => {
  const companiesToUpdate: ICompanySummary[] = [...companies.filter((company: ICompanySummary) => company.user_list.includes(userList))];
  companiesToUpdate.forEach((company) => {
    const deletedUserList = company.user_list.filter((item) => item !== userList);
    onUserListAssignmentChange(companies, setCompanies, apiCaller, company.company_code, company.name, deletedUserList);
  });
};
