import React, { useCallback, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import useApiCaller from '../hooks/use-api-caller';
import { IUserData } from '../types/user-info';
import { ColDef, ISelectCellEditorParams, ModuleRegistry, ClientSideRowModelModule } from 'ag-grid-community';
import { DeliveryRates } from '../constants/user-data';
import Loading from './Loading';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
// import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const SubscriptionEditorGrid: React.FC = () => {
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any[]>([]);
  const [searching, setSearching] = useState<boolean>(false);

  const apiCaller = useApiCaller();

  const onRateSelectionChanged = ({ newValue, data }: any) => {
    apiCaller
      .updateEmailPreferences({
        email: data.email,
        delivery_rate: newValue.toUpperCase(),
      })
      .then(() => {
        toast.success('Email preferences saved successfully');

        // reload the users from backend to update Grid data
        loadData();
      })
      .catch((err) => {
        console.error(err);
        toast.error('Saving email preferences failed');
      });
  };

  const [columnDefs] = useState<ColDef[]>([
    { minWidth: 150, flex: 1, headerName: 'User Name', field: 'name' },
    { minWidth: 180, flex: 1, headerName: 'Email', field: 'email' },
    {
      width: 160,
      headerName: 'Email Subscription',
      field: 'email_delivery_rate',
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: Object.values(DeliveryRates),
      } as ISelectCellEditorParams,
      onCellValueChanged: onRateSelectionChanged,
    },
    { minWidth: 150, flex: 1, headerName: 'Subscription changed by', field: 'send_email_modified_by' },
    { minWidth: 30, headerName: 'Change date', field: 'send_email_modified_date' },
  ]);

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current!.api.setGridOption('quickFilterText', (document.getElementById('filter-text-box') as HTMLInputElement).value);
  }, []);

  const loadData = () => {
    setSearching(true);

    apiCaller
      .getAllUsers()
      .then((usersResponse: IUserData[]) => {
        const users = usersResponse
          .map((item, i) => {
            item.name = item.name.length === 0 ? item.email : item.name;
            item.id = i;
            item.email_delivery_rate =
              !item.email_delivery_rate || item.email_delivery_rate.length === 0 ? 'PAUSE' : item.email_delivery_rate;
            item.email_delivery_rate = DeliveryRates[item.email_delivery_rate as keyof typeof DeliveryRates];
            return item;
          })
          .filter((item) => item.name.length > 0 && item.email.length > 0)
          .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setRowData(users);
      })
      .catch((err) => {
        console.error(err);
        toast.error('Fetching email preferences failed');
      })
      .finally(() => {
        setSearching(false);
      });
  };

  return (
    <div>
      <div className="flex content-center justify-between h-[42px]">
        <div className={`text-md whitespace-pre-line overflow-x-auto text-gray-500 content-center`}>
          Email subscriptions can be changed in the select box of the grid below.
        </div>

        <div>{searching && <Loading />}</div>
      </div>

      <div className="relative">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <svg
            className="w-4 h-4 text-gray-500 dark:text-gray-400"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
            />
          </svg>
        </div>
        <input
          type="text"
          id="filter-text-box"
          className="mb-4 block w-full p-2 ps-10 text-sm text-gray-600 border border-gray-400 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Quick Filter..."
          onInput={onFilterTextBoxChanged}
        />
      </div>

      <div className="ag-theme-alpine" style={{ height: '470px', width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          getRowId={(gridRow: any) => {
            return gridRow.data.id;
          }}
          singleClickEdit={true}
          rowSelection="single"
          onGridReady={loadData}
        />
      </div>
    </div>
  );
};

export default SubscriptionEditorGrid;
